import appConfig from '@/app.config.json'
import { LoginModel } from '@/services/modules/security'
import { reactive, ref, Ref } from '@vue/composition-api'
import { auth, User } from './auth'

export interface UseLoginParams {
  form?: Ref<HTMLFormElement | null>
  onSuccess?: (user: User) => void
  onError?: (error: any) => void
}

export function useLogin({
  onSuccess = () => {},
  onError = () => {},
}: UseLoginParams) {
  const model = reactive<LoginModel>({
    username: '',
    password: '',
    rememberMe: true,
    applicationId: appConfig.api.applicationId,
  })

  const isSubmitting = ref(false)
  const error = ref<unknown | null>(null)

  async function submit() {
    try {
      isSubmitting.value = true

      const user = await auth.login(model)

      if (!user) {
        throw new Error('Validation failed.')
      }

      onSuccess(user)
    } catch (e) {
      error.value = e
      onError(e)
    } finally {
      isSubmitting.value = false
    }
  }

  return {
    model,
    isSubmitting,
    submit,
    error,
  }
}
